import React from 'react';
import { formatPhoneNumber, RadioGroupInput, Stack, TextInput, Typography } from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { IsPhoneNumberCorrectOptions } from '../suggest-edit-types';
import { contactNumberValidator } from '../suggest-edit-utils';

const PhoneInput = () => {
  const phoneNumberValidationMessage = useStringResource('suggestEditDialog', 'phoneNumberValidationMessage');
  const phoneNumberPlaceholder = useStringResource('suggestEditDialog', 'phoneNumberPlaceholder');
  return (
    <Stack spacing="xs">
      <Typography variant="body1" color="common.muted">
        <SR package="suggestEditDialog" id="optionalPhone" />
      </Typography>
      <TextInput
        name="phone"
        label={phoneNumberPlaceholder}
        fullWidth
        customValidation={contactNumberValidator(phoneNumberValidationMessage, 'phone')}
      />
    </Stack>
  );
};

export const PhoneNumberValidation = ({
  phoneNumber,
  isPhoneNumberCorrect,
}: {
  phoneNumber?: string;
  isPhoneNumberCorrect?: IsPhoneNumberCorrectOptions;
}) => {
  const yesOption = useStringResource('suggestEditDialog', 'yesOption');
  const noOption = useStringResource('suggestEditDialog', 'noOption');
  const didNotPickUpOption = useStringResource('suggestEditDialog', 'didNotPickUpOption');
  return (
    <Stack spacing="xs">
      <Stack spacing="none">
        <Typography variant="button">
          <SR package="suggestEditDialog" id="phoneNumberCorrectCheck" />
        </Typography>
        {!!phoneNumber && <Typography variant="body1">{formatPhoneNumber(phoneNumber)}</Typography>}
        <RadioGroupInput
          name="isPhoneNumberCorrect"
          label=""
          options={[
            { label: yesOption, value: 'YES' },
            { label: noOption, value: 'NO' },
            { label: didNotPickUpOption, value: 'UNKNOWN' },
          ]}
        />
      </Stack>
      {isPhoneNumberCorrect === 'NO' && <PhoneInput />}
    </Stack>
  );
};
