import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from '@garner-health/components-auth';
import { Spinner } from '@garner-health/components-common';
import { getScopeInfo, getTokenClaims, ScopeInfo } from '~/auth';
import {
  CareNavigator,
  careNavigatorClient,
  externalContentClient,
  Network,
  providerClient,
  ProviderMetric,
  ProviderMetricMetadata,
  Specialty,
} from '~/clients';
import logger from '~/logging';

type AppContext = {
  specialtiesById: Map<string, Specialty>;
  metricsById: Map<string, ProviderMetric>;
  metricMetadataByMetricValue: Map<string, ProviderMetricMetadata>;
  careNavigator: CareNavigator | null;
  networks: Network[];
  setCareNavigator: (careNavigator: CareNavigator | null) => void;
  scopeInfo: ScopeInfo | null;
};

const log = logger(__filename);
const emptyMap = new Map();

export const AppStateContext = createContext<AppContext>({
  specialtiesById: emptyMap,
  metricsById: emptyMap,
  metricMetadataByMetricValue: emptyMap,
  careNavigator: null,
  networks: [],
  setCareNavigator: () => {},
  scopeInfo: null,
});

export const AppStateProvider = ({ children }: PropsWithChildren) => {
  const [specialtiesById, setSpecialtiesById] = useState<Map<string, Specialty>>(emptyMap);
  const [metricsById, setMetricsById] = useState<Map<string, ProviderMetric>>(emptyMap);
  const [metricMetadataByMetricValue, setMetricMetadataByMetricValue] =
    useState<Map<string, ProviderMetricMetadata>>(emptyMap);
  const [careNavigator, setCareNavigator] = useState<CareNavigator | null>(null);
  const [networks, setNetworks] = useState<Network[]>([]);
  const [scopeInfo, setScopeInfo] = useState<ScopeInfo | null>(null);

  const auth = useAuth();
  const isLoggedIn = auth.state === 'LOGGED_IN';

  async function retrieveCareNavigatorAndNetworks() {
    const claims = await getTokenClaims();
    const [navigator, clientNetworks] = await Promise.all([
      careNavigatorClient.getCareNavigator(claims.careNavigatorId),
      providerClient.getNetworks(claims.networkIds),
    ]).catch(err => {
      log.error({ err }, 'Error fetching data for app state');
      throw err;
    });
    setCareNavigator(navigator);
    setNetworks(clientNetworks);
    setScopeInfo(getScopeInfo(claims.scope));
  }

  useEffect(() => {
    if (!isLoggedIn) {
      setCareNavigator(null);
      return;
    }
    retrieveCareNavigatorAndNetworks();
  }, [isLoggedIn]);

  useEffect(() => {
    externalContentClient
      .getSpecialties()
      .then(setSpecialtiesById)
      .catch(err => log.error({ err }, 'Error loading specialties from CMS'));
    externalContentClient
      .getMetricDetails()
      .then(setMetricsById)
      .catch(err => log.error({ err }, 'Error loading metrics from CMS'));
    externalContentClient
      .getMetricMetadata()
      .then(setMetricMetadataByMetricValue)
      .catch(err => log.error({ err }, 'Error loading metric metadata from CMS'));
  }, []);
  const value = useMemo(
    () => ({
      specialtiesById,
      metricsById,
      metricMetadataByMetricValue,
      careNavigator,
      networks,
      setCareNavigator,
      scopeInfo,
    }),
    [specialtiesById, metricsById, metricMetadataByMetricValue, careNavigator, networks, scopeInfo],
  );

  if ((!careNavigator || !networks.length) && isLoggedIn) return <Spinner variant="dark" />;

  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
};

export const AppConsumer = AppStateContext.Consumer;

export function useAppState() {
  return useContext(AppStateContext);
}

export function useSpecialtyLabels(specialtyIds: string[]): string[] {
  const specialtiesById = useAppState().specialtiesById;
  const labels: string[] = [];
  for (const id of specialtyIds) {
    const label = specialtiesById.get(id)?.name;
    if (!label) {
      log.warn({ specialtyId: id }, 'Specialty label does not exist in external content store');
    }
    labels.push(label ?? id);
  }
  return labels;
}

export function useSpecialtyLabel(): (specialtyId: string) => string {
  const specialtiesById = useAppState().specialtiesById;

  return specialtyId => {
    const label = specialtiesById.get(specialtyId)?.name;

    if (!label) {
      log.warn({ specialtyId }, 'Specialty label does not exist in external content store');
    }

    return label ?? specialtyId;
  };
}

export function useMethodology(specialtyId: string) {
  const specialtiesById = useAppState().specialtiesById;

  return specialtiesById.get(specialtyId)?.methodology;
}

export function useProviderMetrics(): Map<string, ProviderMetric> {
  return useAppState().metricsById;
}

export function useProviderMetricMetadata(): Map<string, ProviderMetricMetadata> {
  return useAppState().metricMetadataByMetricValue;
}

export function useCareNavigator(): {
  careNavigator: CareNavigator;
  setCareNavigator: (careNavigator: CareNavigator | null) => void;
} {
  const careNavigator = useAppState().careNavigator;
  if (!careNavigator) {
    log.error('No careNavigator in app state');
    throw new Error('No careNavigator in app state');
  }
  return { careNavigator, setCareNavigator: useAppState().setCareNavigator };
}

export function useNetworks() {
  const networks = useAppState().networks;
  if (networks.length === 0) {
    log.error('No networks in app state');
    throw new Error('No networks in app state');
  }
  return networks;
}

export function useScopeInfo() {
  const scopeInfo = useAppState().scopeInfo;
  if (!scopeInfo) return null;
  return scopeInfo;
}
