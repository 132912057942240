export const suggestAnEdit = 'Suggest an edit';
export const disclaimer = 'Our team will review your edits and update the information';
export const optionalAddress = 'Corrected address (Optional)';
export const optionalPhone = 'Corrected phone number (Optional)';
export const optionalFax = 'Corrected fax number (Optional)';
export const cancelButtonText = 'Cancel';
export const submitButtonText = 'Submit';
export const submitButtonDemoText = 'Submit (Disabled for demo)';
export const yesOption = 'Yes';
export const noOption = 'No';
export const notSureOption = 'Not sure';
export const didNotPickUpOption = 'Did not pick up';
export const addressPlaceholder = 'Address';
export const cityPlaceholder = 'City';
export const statePlaceholder = 'State';
export const zipCodePlaceholder = 'Zip Code';
export const phoneNumberPlaceholder = 'Phone number';
export const faxNumberPlaceholder = 'Fax number';
export const phoneNumberCorrectCheck = 'Is the phone number correct?';
export const faxNumberCorrectCheck = 'Is the fax number correct?';
export const addressCorrectCheck = 'Is the address correct?';
export const acceptsNewPatientsCorrectCheck = 'Is this provider accepting new patients?';
export const facilityAcceptsNewPatientsCorrectCheck = 'Is this facility accepting new patients?';
export const specialtyCorrectnessCheck = 'Is the specialty correct?';
export const selectIncorrectSpecialtiesMessage = 'Select the specialties that are incorrect:';
export const disciplinaryActionCheck = 'Check to report disciplinary action against this provider';
export const facilityDisciplinaryActionCheck = 'Check to report disciplinary action against this facility';
export const facilityClosedCheck = 'Check to report if the facility is closed';
export const retiredOrDeceasedCheck = 'Check to report if the provider is no longer practicing';
export const phoneNumberValidationMessage = 'Phone number must be only numbers, with a maximum of 10 digits';
export const faxNumberValidationMessage = 'Fax number must be only numbers, with a maximum of 10 digits';
export const requiredFieldValidationMessage = 'Required';
export const zipCodeValidationMessage = 'Must be 5 or 9 digits';
