import * as resourcePackages from './resource-packages';

type LocalResourcePackages = typeof resourcePackages;
type MergedResourcePackages = {
  [K in keyof LocalResourcePackages]: LocalResourcePackages[K];
};

declare module '@garner-health/lib-ui-content-management' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  /* eslint-disable @typescript-eslint/no-empty-object-type */
  /* eslint-disable @typescript-eslint/consistent-type-definitions */
  interface ResourcePackages extends MergedResourcePackages {}
}
