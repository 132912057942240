import React from 'react';
import { Box, KeyValueList, ScrollingTabs, Tab, Typography } from '@garner-health/components-common';
import { useStringResource } from '@garner-health/lib-ui-content-management';
import { Facility, ProviderDetailParams } from '~/clients';
import { useProviderDetails } from '~/contexts/provider-detail-context';
import { LocationHeader } from './location-header';
import { ProviderLocation } from './provider-location';

export type FacilityDetailsProps = {
  facility: Facility;
  searchParams: ProviderDetailParams;
};

export const FacilityDetails = () => {
  const aboutSectionTitle = useStringResource('providerDetails', 'aboutSectionTitle');
  const detailsSectionAboutLabel = useStringResource('providerDetails', 'aboutSectionNpiLabel');
  const locationSectionTitle = useStringResource('providerDetails', 'locationSectionTitle');

  const details = useProviderDetails();
  if (!details?.facility) return;
  const { facility } = details;

  return (
    <ScrollingTabs>
      <Tab label={aboutSectionTitle}>
        <Box paddingBottom="md">
          <Typography variant="h3">{aboutSectionTitle}</Typography>
        </Box>
        <KeyValueList items={[{ key: detailsSectionAboutLabel, value: facility.npi }]} />
      </Tab>
      <Tab label={locationSectionTitle}>
        <LocationHeader />
        <ProviderLocation />
      </Tab>
    </ScrollingTabs>
  );
};
