import { FieldValues } from 'react-hook-form';
import { AnnotationField, FacilityLocation, Professional, ProfessionalLocation } from '~/clients';
import { SuggestEditFormData } from './suggest-edit-types';

type SuggestEditAnnotationParams = {
  location: ProfessionalLocation | FacilityLocation;
  data: SuggestEditFormData;
  professional?: Professional;
};

export function sanitizeContactInfo(contactNumber: string) {
  return contactNumber.replace(/\D/g, '');
}

export function contactNumberValidator(message: string, contactNumberKey: 'phone' | 'fax') {
  return {
    validation: {
      validator: (_: unknown, formValues: FieldValues) => {
        const contactNumber = formValues[contactNumberKey];
        if (!contactNumber) return true;
        const cleanedContactNumber = sanitizeContactInfo(contactNumber);
        return /^\d{10}$/.test(cleanedContactNumber);
      },
      renderMessage: () => message,
    },
  };
}

type CreateAnnotationParams<T extends string | boolean> = {
  field: AnnotationField;
  value: T;
  isCorrect: boolean | null;
  correctedValue?: T;
};
export function createAnnotation<T extends string | boolean>({
  field,
  value,
  isCorrect,
  correctedValue,
}: CreateAnnotationParams<T>) {
  return {
    field,
    value,
    isCorrect,
    correctedValue,
  };
}

type AddressAnnotationKeys = Extract<keyof SuggestEditFormData, 'street1' | 'city' | 'state' | 'zipCode'>;
export function createAddressAnnotations({ location, data }: SuggestEditAnnotationParams) {
  const fields: AddressAnnotationKeys[] = ['street1', 'city', 'state', 'zipCode'];

  return fields.map(field => {
    const isBeingUpdated = !!data[field];
    const value = field === 'street1' ? location.lines[0] : location[field];
    const correctedValue = isBeingUpdated ? data[field] : undefined;

    return createAnnotation({
      field,
      value,
      isCorrect: data.isAddressCorrect === 'YES',
      correctedValue: field === 'zipCode' && correctedValue ? sanitizeContactInfo(correctedValue) : correctedValue,
    });
  });
}

export function createPhoneNumberAnnotation({ location, data }: SuggestEditAnnotationParams) {
  const { isPhoneNumberCorrect, phone: newPhoneNumber } = data;
  const phoneNumberField = 'phoneNumber';
  const currPhoneNumber = location.phoneNumber;
  if (isPhoneNumberCorrect === 'UNKNOWN') {
    return createAnnotation({
      field: phoneNumberField,
      value: currPhoneNumber ?? '',
      isCorrect: null,
      correctedValue: undefined,
    });
  }
  if (isPhoneNumberCorrect === 'YES') {
    return createAnnotation({
      field: phoneNumberField,
      value: currPhoneNumber ?? '',
      isCorrect: true,
      correctedValue: undefined,
    });
  }
  return createAnnotation({
    field: phoneNumberField,
    value: currPhoneNumber ?? '',
    isCorrect: false,
    correctedValue: newPhoneNumber ? sanitizeContactInfo(newPhoneNumber) : undefined,
  });
}

export function createFaxNumberAnnotation({ location, data }: SuggestEditAnnotationParams) {
  const { isFaxNumberCorrect, fax: newFaxNumber } = data;
  const faxNumberField = 'faxNumber';
  const currFaxNumber = location.faxNumber;

  if (isFaxNumberCorrect === 'YES') {
    return createAnnotation({
      field: faxNumberField,
      value: currFaxNumber ?? '',
      isCorrect: true,
      correctedValue: undefined,
    });
  }
  return createAnnotation({
    field: faxNumberField,
    value: currFaxNumber ?? '',
    isCorrect: false,
    correctedValue: newFaxNumber ? sanitizeContactInfo(newFaxNumber) : undefined,
  });
}

export function createSpecialtyAnnotation({ data, professional }: SuggestEditAnnotationParams) {
  if (data.isSpecialtyCorrect === 'YES') return [];

  const professionalSpecialties = professional?.specialties ? [...professional.specialties.keys()] : [];
  const specialtyKeys = Object.keys(data).filter(
    key => professionalSpecialties.includes(key) && !!data[key as keyof SuggestEditFormData],
  );

  // Handles annotations for providers with 1 specialty
  if (!specialtyKeys.length) {
    return [
      createAnnotation({
        field: 'specialty',
        value: professional?.specialties.keys().next().value ?? '',
        isCorrect: false,
      }),
    ];
  }
  // Handles annotations for providers with multiple specialty
  return specialtyKeys.map(specialty => {
    return createAnnotation({
      field: 'specialty',
      value: specialty,
      isCorrect: false,
    });
  });
}
