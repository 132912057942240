import React from 'react';
import { FieldValues } from 'react-hook-form';
import {
  FormattedAddress,
  RadioGroupInput,
  Stack,
  TextInput,
  ThreeColumnLayout,
  Typography,
} from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { LocationBase } from '~/clients';
import { GenericCorrectOptions } from '../suggest-edit-types';
import { sanitizeContactInfo } from '../suggest-edit-utils';
import { StateDropDownInput } from './state-dropdown-input';

const ZIP_CODE_REGEX_PATTERN = /^\d{5}(\d{4})?$/;

type AddressFormValues = {
  street1: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zipCode: string | undefined;
};
function isPartiallyUpdatedAddress(addressValues: AddressFormValues): boolean {
  const values = Object.values(addressValues);

  const allDefined = values.every(value => !!value);
  const allUndefined = values.every(value => !value);

  return !(allDefined || allUndefined);
}

function requiredFieldValidator(message: string, isPartiallyUpdated: boolean, addressKey: string) {
  return {
    validation: {
      validator: (_: unknown, formValues: FieldValues) => {
        const formValue = formValues[addressKey];
        return !isPartiallyUpdated || !!formValue;
      },
      renderMessage: () => message,
    },
  };
}

function zipCodeValidator(message: string, isPartiallyUpdated: boolean) {
  return {
    validation: {
      validator: (_: unknown, formValues: FieldValues) => {
        const { zipCode } = formValues;
        if (isPartiallyUpdated && !zipCode) return false;
        if (!zipCode) return true;
        const cleanedZipCode = sanitizeContactInfo(zipCode);
        return ZIP_CODE_REGEX_PATTERN.test(cleanedZipCode);
      },
      renderMessage: () => message,
    },
  };
}

const AddressInput = ({ isPartiallyUpdated }: { isPartiallyUpdated: boolean }) => {
  const requiredFieldValidation = useStringResource('suggestEditDialog', 'requiredFieldValidationMessage');
  const zipCodeValidationMessage = useStringResource('suggestEditDialog', 'zipCodeValidationMessage');
  const addressPlaceholder = useStringResource('suggestEditDialog', 'addressPlaceholder');
  const cityPlaceholder = useStringResource('suggestEditDialog', 'cityPlaceholder');
  const statePlaceholder = useStringResource('suggestEditDialog', 'statePlaceholder');
  const zipCodePlaceholder = useStringResource('suggestEditDialog', 'zipCodePlaceholder');

  return (
    <Stack spacing="xs">
      <Typography variant="body1" color="common.muted">
        <SR package="suggestEditDialog" id="optionalAddress" />
      </Typography>
      <TextInput
        name="street1"
        label={addressPlaceholder}
        fullWidth
        customValidation={requiredFieldValidator(requiredFieldValidation, isPartiallyUpdated, 'street1')}
      />

      <ThreeColumnLayout>
        <ThreeColumnLayout.Column1>
          <TextInput
            name="city"
            label={cityPlaceholder}
            customValidation={requiredFieldValidator(requiredFieldValidation, isPartiallyUpdated, 'city')}
          />
        </ThreeColumnLayout.Column1>

        <ThreeColumnLayout.Column2>
          <StateDropDownInput
            name="state"
            label={statePlaceholder}
            customValidation={requiredFieldValidator(requiredFieldValidation, isPartiallyUpdated, 'state')}
          />
        </ThreeColumnLayout.Column2>

        <ThreeColumnLayout.Column3>
          <TextInput
            name="zipCode"
            label={zipCodePlaceholder}
            customValidation={zipCodeValidator(zipCodeValidationMessage, isPartiallyUpdated)}
          />
        </ThreeColumnLayout.Column3>
      </ThreeColumnLayout>
    </Stack>
  );
};

type AddressValidationProps = {
  location: LocationBase;
  isAddressCorrect?: GenericCorrectOptions;
  addressFormValues: AddressFormValues;
};
export const AddressValidation = ({ location, isAddressCorrect, addressFormValues }: AddressValidationProps) => {
  const yesOption = useStringResource('suggestEditDialog', 'yesOption');
  const noOption = useStringResource('suggestEditDialog', 'noOption');

  const isPartiallyUpdated = isPartiallyUpdatedAddress(addressFormValues);
  return (
    <Stack spacing="xs">
      <>
        <Typography variant="button">
          <SR package="suggestEditDialog" id="addressCorrectCheck" />
        </Typography>
        <FormattedAddress
          address={{
            lines: location.lines,
            city: location.city,
            state: location.state,
            zipCode: location.zipCode,
          }}
        />
        <RadioGroupInput
          name="isAddressCorrect"
          label=""
          options={[
            { label: yesOption, value: 'YES' },
            { label: noOption, value: 'NO' },
          ]}
        />
      </>
      {isAddressCorrect === 'NO' && <AddressInput isPartiallyUpdated={isPartiallyUpdated} />}
    </Stack>
  );
};
