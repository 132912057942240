import React from 'react';
import { Box, Checkbox, RadioGroupInput, Stack, Typography } from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { ProfessionalSpecialty } from '~/clients';
import { useSpecialtyLabels } from '~/contexts';
import { GenericCorrectOptions } from '../suggest-edit-types';

const SpecialtyList = ({ specialties }: { specialties: string[] }) => {
  const specialtyLabels = useSpecialtyLabels(specialties);
  if (specialties.length < 2) return null;

  return (
    <Stack spacing="none" paddingTop="sm">
      <Typography variant="body1">
        <SR package="suggestEditDialog" id="selectIncorrectSpecialtiesMessage" />
      </Typography>
      <Box>
        {specialties.map((specialty, index) => {
          return (
            <Checkbox
              key={specialty}
              name={specialty}
              label={<Typography variant="body2">{specialtyLabels[index]}</Typography>}
            />
          );
        })}
      </Box>
    </Stack>
  );
};

export const SpecialtyValidation = ({
  isSpecialtyCorrect,
  specialties: rawSpecialties,
}: {
  isSpecialtyCorrect?: GenericCorrectOptions;
  specialties: Map<string, ProfessionalSpecialty>;
}) => {
  const yesOption = useStringResource('suggestEditDialog', 'yesOption');
  const noOption = useStringResource('suggestEditDialog', 'noOption');

  const specialties = [...rawSpecialties.keys()];
  return (
    <Stack spacing="none">
      <Typography variant="button">
        <SR package="suggestEditDialog" id="specialtyCorrectnessCheck" />
      </Typography>
      <RadioGroupInput
        name="isSpecialtyCorrect"
        label=""
        options={[
          { label: yesOption, value: 'YES' },
          { label: noOption, value: 'NO' },
        ]}
      />

      {isSpecialtyCorrect === 'NO' && <SpecialtyList specialties={specialties} />}
    </Stack>
  );
};
