import constants from 'app-constants';
import { getAuth } from '@garner-health/components-auth';
import createModuleLogger, { Bindings, createLogger } from '@garner-health/logging';
import { bowserParser } from './browser';

const platform = bowserParser.getPlatform();

const sessionId = crypto.randomUUID();
const log = createLogger({
  logLevel: constants.logLevel,
  logDestination: {
    path: '/api/logs',
    async lazyBindings(): Promise<Bindings> {
      try {
        const auth = getAuth();
        const claims = await auth.getTokenClaims();
        return {
          careNavigatorId: claims['custom:care_navigator_id'],
          clientId: claims['custom:client_id'],
        };
      } catch {
        // Can't log here because it would create a potentially infinite loop of logging errors
        return {};
      }
    },
  },
}).child({
  component: 'datapro-ui',
  strSessionId: sessionId,
  strBrowser: bowserParser.getBrowserName(),
  strBrowserVersion: bowserParser.getBrowserVersion(),
  strOperatingSystem: bowserParser.getOSName(),
  strPlatformVendor: platform.vendor,
  strPlatformType: platform.type,
  strUserAgent: bowserParser.getUA(),
  strAppVersion: GARNER_VERSION,
  strBuildNumber: GARNER_BUILD_NUMBER,
  strCommitSha: GARNER_COMMIT_SHA,
});

if (!['development', 'test'].includes(process.env.NODE_ENV!)) {
  const externalLog = log.child({ module: 'datapro-ui-external' });
  Object.assign(console, {
    log: (...args: unknown[]) => externalLog.info({ context: args }),
    info: (...args: unknown[]) => externalLog.info({ context: args }),
    warn: (...args: unknown[]) => externalLog.warn({ context: args }),
    error: (...args: unknown[]) => externalLog.error({ context: args }),
    fatal: (...args: unknown[]) => externalLog.fatal({ context: args }),
    trace: (...args: unknown[]) => externalLog.trace({ context: args }),
    debug: (...args: unknown[]) => externalLog.debug({ context: args }),
  });
}

export default function logger(module: string, bindings?: Omit<Bindings, 'module'>) {
  return createModuleLogger(module, { log }, bindings as Bindings & { module: never });
}
