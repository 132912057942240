import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TwoColumnLayout } from '@garner-health/components-common';
import { Mapbox, Marker } from '@garner-health/components-map';
import { ProviderResultItem, ProvidersResponseSetType } from '~/clients';
import { providerKeyResolver } from '~/util';
import { useSortedResults } from '~/hooks/use-sorted-results';
import { routes } from '~/router/routes';
import { ProvidersTable } from '~/components/provider-search/providers-table';
import { SearchFormData } from '~/components/provider-search/search-form';
import { SortByForm } from '~/components/provider-search/sort-by-form';
import { TieredNetworkCallout } from '~/components/provider-search/tiered-network-callout';

function useMarkers(providers: ProviderResultItem[]) {
  const [markers, setMarkers] = useState<Marker[]>([]);

  useEffect(() => {
    const newMarkers: Marker[] = providers.map(provider => ({
      type: provider.isTopProvider ? 'primary' : 'secondary',
      key: providerKeyResolver(provider),
      lat: provider.location.position.lat,
      lng: provider.location.position.lng,
    }));

    setMarkers(newMarkers);
  }, [providers]);

  return markers;
}

function useNavigateToProviderDetails({ formData }: { formData: SearchFormData }) {
  const navigate = useNavigate();
  const networkId = formData.network.value;

  return (item: ProviderResultItem) => {
    let metricIds: string[] = [];
    if (formData.careGoal) {
      metricIds = formData.careGoal.metricIds.filter(Boolean);
    } else if (item.type === 'physician') {
      metricIds = item.metrics?.filter(metric => metric.id.includes(item.specialty)).map(metric => metric.id) ?? [];
    }

    navigate(
      routes.providerDetails({
        id: item.id,
        locationId: item.location.id,
        specialty: item.specialty,
        networkId,
        metricIds,
      }),
    );
  };
}

type SearchResultsProps = {
  providers: ProviderResultItem[];
  responseSetType: ProvidersResponseSetType;
  formData: SearchFormData;
  isTieredNetwork?: boolean;
};

/**
 * Wraps Providers list in a form with a SelectInput that is used to sort items in the providers list
 */
export const SearchResults = ({ providers, responseSetType, formData, isTieredNetwork }: SearchResultsProps) => {
  const { sortMethod, setSortMethod, sortedProviders } = useSortedResults(providers, responseSetType);
  const markers = useMarkers(sortedProviders);
  const onNavigate = useNavigateToProviderDetails({ formData });
  const [selectedKey, setSelectedKey] = useState<string | undefined>(undefined);

  return (
    <TwoColumnLayout containScroll>
      <TwoColumnLayout.Column1>
        <Box padding="sm">
          <SortByForm searchType={responseSetType} sortMethod={sortMethod} onChange={setSortMethod} />
          <ProvidersTable
            searchType={responseSetType}
            providers={sortedProviders}
            configureRow={(item, key) => ({
              onMouseEnter: () => setSelectedKey(key),
              onMouseLeave: () => setSelectedKey(undefined),
              onClick: () => onNavigate(item),
            })}
            selectedKey={selectedKey}
          />
          {!!isTieredNetwork && <TieredNetworkCallout />}
        </Box>
      </TwoColumnLayout.Column1>

      <TwoColumnLayout.Column2>
        <Mapbox
          minHeight={{ xs: 300, md: '100%' }}
          interactive
          center={formData.location.position}
          markers={markers}
          selectedKey={selectedKey}
          renderMarker={marker => ({
            onMouseEnter: () => setSelectedKey(marker.key),
            onMouseLeave: () => setSelectedKey(undefined),
          })}
        />
      </TwoColumnLayout.Column2>
    </TwoColumnLayout>
  );
};
