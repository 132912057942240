import logger from '~/logging';

export type Position = {
  lat: number;
  lng: number;
};

const log = logger(__filename);

export function serializePosition(position: Position): string {
  return `${position.lat},${position.lng}`;
}

export function deserializePosition(position: string): Position | null {
  const [lat, lng] = position.split(',').map(parseFloat);
  if (Number.isNaN(lat) || Number.isNaN(lng)) {
    log.error({ strPosition: position }, 'Invalid lat/lng when loading from URL search params');
    return null;
  }
  return { lat, lng };
}
