import React from 'react';
import { Checkbox, Typography } from '@garner-health/components-common';
import { SR } from '@garner-health/lib-ui-content-management';

export const ActivelyTreatingPatientsValidation = ({ isProfessional }: { isProfessional: boolean }) => {
  const labelId = isProfessional ? 'retiredOrDeceasedCheck' : 'facilityClosedCheck';
  return (
    <Checkbox
      name="isNotActivelyTreatingPatients"
      label={
        <Typography variant="button">
          <SR package="suggestEditDialog" id={labelId} />
        </Typography>
      }
    />
  );
};
