import React from 'react';
import { ExternalLink } from '@garner-health/components-common';
import { analytics, Event } from '~/analytics';

type SupportCtaProps = {
  supportEmail: string;
  event: Event;
};

export const SupportCta = ({ supportEmail, event }: SupportCtaProps) => {
  return (
    <ExternalLink onClick={() => analytics.track(event)} href={`mailto:${supportEmail}`}>
      {supportEmail}
    </ExternalLink>
  );
};
