import parseISO from 'date-fns/parseISO';
import { objectToCamel } from 'ts-case-convert';
import { CareNavigatorServiceV1, GetCareNavigatorResponses } from '@garner-health/api-client/care-navigator-v1';
import { failure, Response, success } from '@garner-health/lib-ts-common';
import createLogger from '~/logging';
import { baseClientOptions, getDefaultHeaders } from './common';

const log = createLogger(__filename);

type CareNavigatorApiResponse = GetCareNavigatorResponses['200']['json'];

export type CareNavigator = {
  id: number;
  clientId: number;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: Date;
  updatedAt: Date;
  preferredName?: string;
  jobTitle?: string;
  dataSecurityAgreement?: {
    createdAt: Date;
    agreed: true;
  };
};

type UpdateCareNavigatorParams = {
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  jobTitle?: string;
  agreedToDataSecurityAgreement?: true;
};

class CareNavigatorClient {
  private client: CareNavigatorServiceV1;
  constructor() {
    this.client = new CareNavigatorServiceV1(baseClientOptions);
  }

  async getCareNavigator(id: number): Promise<CareNavigator | null> {
    return await this.client
      .get('/care-navigators/{id}', {
        headers: await getDefaultHeaders(),
        params: { id },
      })
      .onStatus(200, r => this.formatCareNavigator(r.json))
      .onStatus(404, r => {
        log.error({ req: { id: r.json.requestId }, careNavigatorId: id }, 'Care navigator not found');
        return null;
      })
      .catch(err => {
        log.error({ err, careNavigatorId: id }, 'Error getting care navigator');
        return null;
      });
  }

  async updateCareNavigator(
    id: number,
    params: UpdateCareNavigatorParams,
  ): Promise<Response<CareNavigator, 'notFound' | 'unknownError'>> {
    const { firstName, lastName, preferredName, jobTitle, agreedToDataSecurityAgreement } = params;
    return await this.client
      .patch('/care-navigators/{id}', {
        headers: await getDefaultHeaders(),
        params: { id },
        json: {
          first_name: firstName,
          last_name: lastName,
          preferred_name: preferredName,
          job_title: jobTitle,
          data_security_agreement: agreedToDataSecurityAgreement ? { agreed: true } : undefined,
        },
      })
      .onStatus(200, r => {
        log.debug({ careNavigatorId: id }, 'Updated care navigator');
        return success(this.formatCareNavigator(r.json));
      })
      .onStatus(404, r => {
        log.error({ req: { id: r.json.requestId }, careNavigatorId: id }, 'Care navigator not found when updating');
        return failure('notFound');
      })
      .catch(err => {
        log.error({ err, careNavigatorId: id, context: params }, 'Error updating care navigator');
        return failure('unknownError');
      });
  }

  private formatCareNavigator(response: CareNavigatorApiResponse): CareNavigator {
    return {
      ...objectToCamel(response),
      createdAt: parseISO(response.created_at),
      updatedAt: parseISO(response.updated_at),
      dataSecurityAgreement: response.data_security_agreement
        ? {
            createdAt: parseISO(response.data_security_agreement.created_at),
            agreed: response.data_security_agreement.agreed,
          }
        : undefined,
    };
  }
}

export const careNavigatorClient = new CareNavigatorClient();
