import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthSso } from '@garner-health/components-auth';
import { FixedWidthLayout } from '@garner-health/components-common';
import { useStringResource } from '@garner-health/lib-ui-content-management';
import { analytics, Event } from '~/analytics';
import { getTokenClaims } from '~/auth';
import logger from '~/logging';
import { routes } from '~/router/routes';

const log = logger(__filename);

export const SsoAuthPage = () => {
  const navigate = useNavigate();

  return (
    <FixedWidthLayout maxWidth="sm">
      <AuthSso
        headingText={useStringResource('auth', 'headingText')}
        errorText={{
          UNKNOWN: useStringResource('auth', 'unknownErrorText'),
          NOT_AUTHORIZED: useStringResource('auth', 'notAuthorizedSsoErrorText'),
        }}
        loginPageLink={routes.login()}
        loginPageText={useStringResource('auth', 'loginPageText')}
        signInCallback={async ({ email, redirect }) => {
          let claimEmail = email;
          // We won't have an email when user initiates login flow from external link (rather than our login page)
          if (!claimEmail) {
            const claims = await getTokenClaims();
            if (!claims.email) {
              log.error({ context: { claims } }, 'Email is missing from token claims');
            } else {
              claimEmail = claims.email;
            }
          }
          analytics.track(Event.SIGN_IN, { email: claimEmail, authType: 'SSO' });
          return redirect ? navigate(redirect) : navigate(routes.home());
        }}
      />
    </FixedWidthLayout>
  );
};
