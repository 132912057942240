import React, { ReactNode } from 'react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useForm } from 'react-hook-form';
import { Box, Form, FormattedAddress, Grid, SelectInput, Stack } from '@garner-health/components-common';
import { Mapbox } from '@garner-health/components-map';
import { useStringResource } from '@garner-health/lib-ui-content-management';
import { ProfessionalLocation } from '~/clients';
import {
  ProviderDetailContextType,
  useProviderDetailDispatch,
  useProviderDetails,
} from '~/contexts/provider-detail-context';
import { IconLabel } from '../common';
import { LocationInfo } from './location-info';
import { ProviderHours } from './provider-hours';

const AddressWithIcon = ({ lines, city, state, zipCode }: ProfessionalLocation) => {
  return (
    <IconLabel
      icon={<LocationOnOutlinedIcon />}
      body={
        <FormattedAddress
          address={{
            lines,
            city,
            state,
            zipCode,
          }}
        />
      }
    />
  );
};

const AddressWithoutIcon = ({ lines, city, state, zipCode }: ProfessionalLocation) => {
  return (
    <Stack paddingY="xs">
      <FormattedAddress
        address={{
          lines,
          city,
          state,
          zipCode,
        }}
      />
    </Stack>
  );
};

const ProviderLocationBody = ({ professional, facility, locationId }: ProviderDetailContextType) => {
  const dispatch = useProviderDetailDispatch();
  const locationLabel = useStringResource('providerDetails', 'locationSectionTitle');

  const form = useForm<{ location: string }>({ defaultValues: { location: locationId } });

  const location = facility
    ? { ...facility.location, acceptsNewPatients: undefined }
    : professional.locations.get(locationId);
  if (!location) return;

  // Options rendered in the dropdown menu when there are multiple locations
  const locationMenuOptions =
    professional &&
    Array.from(professional.locations.entries()).map(([key, loc]) => {
      return { value: key, label: <AddressWithoutIcon {...loc} /> };
    });

  const selectedLocationMap = new Map<string, ReactNode>();
  professional?.locations.forEach((loc, key) => {
    selectedLocationMap.set(key, <AddressWithIcon {...loc} />);
  });

  const renderValue = (value: string) => selectedLocationMap.get(value);
  const onLocationSelectChange = (value: string) => dispatch({ type: 'updateLocation', locationId: value });

  const locationInfo = (
    <LocationInfo
      phoneNumber={location.phoneNumber}
      faxNumber={location.faxNumber}
      //NB: We only show limited availability when `acceptsNewPatients` is confirmed `false`.
      //Having acceptsNewPatients=undefined is treated as limitedAvailability=false
      limitedAvailability={location.acceptsNewPatients === false}
    />
  );

  return (
    <>
      {locationMenuOptions && locationMenuOptions.length > 1 ? (
        <Form form={form}>
          <Stack spacing="sm">
            <Grid>
              <Grid.Item xs={24} md={4}>
                <SelectInput
                  aria-label={locationLabel}
                  size="small"
                  allowClear={false}
                  label=""
                  options={locationMenuOptions}
                  name="location"
                  onChange={onLocationSelectChange}
                  renderValue={renderValue}
                />
              </Grid.Item>
            </Grid>
            <Stack paddingX="sm">{locationInfo}</Stack>
          </Stack>
        </Form>
      ) : (
        <Stack spacing="sm">
          <AddressWithIcon {...location} />
          {locationInfo}
        </Stack>
      )}

      <Box paddingBottom="xs">
        <Mapbox borderRadius="sm" minHeight={300} center={location.position} />
      </Box>

      {!!location.hours?.length && <ProviderHours hours={location.hours} />}
    </>
  );
};

export const ProviderLocation = () => {
  const details = useProviderDetails();
  if (!details) return;
  return <ProviderLocationBody {...details} />;
};
