import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, Form, Spinner, Stack, Typography } from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { analytics, Event } from '~/analytics';
import {
  Annotation,
  Facility,
  FacilityLocation,
  Professional,
  ProfessionalLocation,
  providerClient,
  ProviderDetailParams,
} from '~/clients';
import { useScopeInfo } from '~/contexts';
import logger from '~/logging';
import { useProviderDetails } from '~/contexts/provider-detail-context';
import {
  AcceptsNewPatientsValidation,
  ActivelyTreatingPatientsValidation,
  AddressValidation,
  DisciplinaryActionValidation,
  FaxNumberValidation,
  PhoneNumberValidation,
  SpecialtyValidation,
} from './suggest-edit-components';
import { SuggestEditFormData } from './suggest-edit-types';
import {
  createAddressAnnotations,
  createAnnotation,
  createFaxNumberAnnotation,
  createPhoneNumberAnnotation,
  createSpecialtyAnnotation,
} from './suggest-edit-utils';

type SuggestEditDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const log = logger(__filename);

type FormFieldToAnnotationFunctionParams = {
  correctedValue: string | boolean;
  location: ProfessionalLocation | FacilityLocation;
  data: SuggestEditFormData;
  professional?: Professional;
};

type DialogFooterProps = {
  onClose: () => void;
  isAnnotationLoading: boolean;
  isFormEmpty: boolean;
};

type SuggestEditCommon = {
  location: ProfessionalLocation;
  isOpen: boolean;
  onClose: () => void;
  searchParams: ProviderDetailParams;
};

type ProfessionalSuggestEdit = SuggestEditCommon & {
  professional: Professional;
  facility?: never;
};

type FacilitySuggestEdit = SuggestEditCommon & {
  professional?: never;
  facility: Facility;
};

type SuggestEditDialogBodyProps = ProfessionalSuggestEdit | FacilitySuggestEdit;
type FormFieldToAnnotationFn = (params: FormFieldToAnnotationFunctionParams) => Annotation[];

const formFieldToAnnotation: Record<keyof SuggestEditFormData, FormFieldToAnnotationFn | undefined> = {
  isPhoneNumberCorrect: ({ location, data }) => {
    if (!data.isPhoneNumberCorrect) return [];
    const phoneAnnotation = createPhoneNumberAnnotation({ location, data });
    return [phoneAnnotation];
  },
  isFaxNumberCorrect: ({ location, data }) => {
    if (!data.isFaxNumberCorrect) return [];
    const faxAnnotation = createFaxNumberAnnotation({ location, data });
    return [faxAnnotation];
  },
  isAddressCorrect: ({ location, data }) => {
    if (!data.isAddressCorrect) return [];
    return createAddressAnnotations({ location, data });
  },
  acceptsNewPatients: ({ correctedValue }) => {
    const isCorrect = correctedValue === 'YES';
    const correctedValueBool = !isCorrect ? false : undefined;
    return [
      createAnnotation({
        field: 'acceptsNewPatients',
        value: true,
        isCorrect,
        correctedValue: correctedValueBool,
      }),
    ];
  },
  isReportingDisciplinaryAction: ({ data }) => {
    if (!data.isReportingDisciplinaryAction) return [];
    return [
      createAnnotation({
        field: 'knownDisciplinaryAction',
        value: false,
        isCorrect: false,
        correctedValue: true,
      }),
    ];
  },
  isNotActivelyTreatingPatients: ({ data }) => {
    if (!data.isNotActivelyTreatingPatients) return [];
    return [
      createAnnotation({
        field: 'activelyTreatingPatients',
        value: true,
        isCorrect: false,
        correctedValue: false,
      }),
    ];
  },
  isSpecialtyCorrect: ({ data, location, professional }) => {
    if (!data.isSpecialtyCorrect) return [];
    return createSpecialtyAnnotation({ data, location, professional });
  },
  street1: undefined,
  city: undefined,
  state: undefined,
  zipCode: undefined,
  phone: undefined,
  fax: undefined,
};

const DialogFooter = ({ onClose, isAnnotationLoading, isFormEmpty }: DialogFooterProps) => {
  const isDemo = !!useScopeInfo()?.isDemo;

  return (
    <>
      <Button onClick={() => onClose()}>
        <SR package="suggestEditDialog" id="cancelButtonText" />
      </Button>

      <Button
        type="submit"
        variant="primary"
        disabled={isAnnotationLoading || isDemo || isFormEmpty}
        form="suggest-edit-form"
      >
        {isAnnotationLoading ? (
          <Spinner variant="dark" />
        ) : (
          <SR package="suggestEditDialog" id={isDemo ? 'submitButtonDemoText' : 'submitButtonText'} />
        )}
      </Button>
    </>
  );
};

const SuggestEditDialogBody = ({
  professional,
  facility,
  location,
  isOpen,
  searchParams,
  onClose,
}: SuggestEditDialogBodyProps) => {
  const suggestAnEdit = useStringResource('suggestEditDialog', 'suggestAnEdit');
  const form = useForm<SuggestEditFormData>();

  const [isAnnotationLoading, setIsAnnotationLoading] = useState(false);
  const [
    isPhoneNumberCorrect,
    isFaxNumberCorrect,
    isAddressCorrect,
    acceptsNewPatients,
    isReportingDisciplinaryAction,
    isNotActivelyTreatingPatients,
    isSpecialtyCorrect,
    street1,
    city,
    state,
    zipCode,
  ] = form.watch([
    'isPhoneNumberCorrect',
    'isFaxNumberCorrect',
    'isAddressCorrect',
    'acceptsNewPatients',
    'isReportingDisciplinaryAction',
    'isNotActivelyTreatingPatients',
    'isSpecialtyCorrect',
    'street1',
    'city',
    'state',
    'zipCode',
  ]);

  function formSubmitHandler(data: SuggestEditFormData) {
    setIsAnnotationLoading(true);

    // Build annotations
    const annotations: Annotation[] = [];
    const fields = [];
    for (const [key, correctedValue] of Object.entries(data)) {
      // Handling false and blank newPatients
      if (!correctedValue) continue;
      fields.push(key);
      const fn = formFieldToAnnotation[key as keyof SuggestEditFormData];
      const subAnnotations = fn?.({ correctedValue, location, data, professional });

      if (subAnnotations) annotations.push(...subAnnotations);
    }

    analytics.track(Event.SUGGEST_EDIT, { fields });
    const providerId = professional ? professional.id : facility.id;

    providerClient
      .createAnnotation({
        providerId,
        searchParams,
        annotations,
      })
      .catch(err => {
        log.error({ err }, 'Unexpected error creating annotation');
      })
      .finally(() => {
        localOnClose();
      });
  }

  function localOnClose() {
    setIsAnnotationLoading(false);
    form.reset();
    onClose();
  }

  const isFormEmpty =
    !isAddressCorrect &&
    !isPhoneNumberCorrect &&
    !isFaxNumberCorrect &&
    !acceptsNewPatients &&
    !isReportingDisciplinaryAction &&
    !isNotActivelyTreatingPatients &&
    !isSpecialtyCorrect;

  return (
    <Dialog
      open={isOpen}
      title={suggestAnEdit}
      body={
        <Form form={form} onSubmit={formSubmitHandler} id="suggest-edit-form">
          <Stack spacing="sm">
            <Typography variant="body1">
              <SR package="suggestEditDialog" id="disclaimer" />
            </Typography>
            <PhoneNumberValidation phoneNumber={location.phoneNumber} isPhoneNumberCorrect={isPhoneNumberCorrect} />
            <FaxNumberValidation faxNumber={location.faxNumber} isFaxNumberCorrect={isFaxNumberCorrect} />
            <AddressValidation
              location={location}
              isAddressCorrect={isAddressCorrect}
              addressFormValues={{ street1, city, state, zipCode }}
            />
            <AcceptsNewPatientsValidation isProfessional={!!professional} />
            {!!professional && (
              <SpecialtyValidation isSpecialtyCorrect={isSpecialtyCorrect} specialties={professional.specialties} />
            )}
            <DisciplinaryActionValidation isProfessional={!!professional} />
            <ActivelyTreatingPatientsValidation isProfessional={!!professional} />
          </Stack>
        </Form>
      }
      footer={
        <DialogFooter onClose={localOnClose} isAnnotationLoading={isAnnotationLoading} isFormEmpty={isFormEmpty} />
      }
    />
  );
};

export const SuggestEditDialog = ({ isOpen, onClose }: SuggestEditDialogProps) => {
  const details = useProviderDetails();
  if (!details) return;
  const { professional, facility, locationId, specialty, networkId } = details;
  const searchParams = { locationId, specialty, networkId };
  const location = professional ? professional.locations.get(locationId) : facility.location;
  if (!location) {
    log.error(
      { locationId, professionalId: professional?.id, facilityId: facility?.id },
      'location not found in provider',
    );
    return;
  }

  const props = { isOpen, onClose, location, searchParams };

  if (professional) {
    return <SuggestEditDialogBody {...props} professional={professional} />;
  }

  return <SuggestEditDialogBody {...props} facility={facility} />;
};
