import React from 'react';
import { formatPhoneNumber, RadioGroupInput, Stack, TextInput, Typography } from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { GenericCorrectOptions } from '../suggest-edit-types';
import { contactNumberValidator } from '../suggest-edit-utils';

const FaxInput = () => {
  const faxNumberValidationMessage = useStringResource('suggestEditDialog', 'faxNumberValidationMessage');
  const faxNumberPlaceholder = useStringResource('suggestEditDialog', 'faxNumberPlaceholder');
  return (
    <Stack spacing="xs">
      <Typography variant="body1" color="common.muted">
        <SR package="suggestEditDialog" id="optionalFax" />
      </Typography>
      <TextInput
        name="fax"
        label={faxNumberPlaceholder}
        fullWidth
        customValidation={contactNumberValidator(faxNumberValidationMessage, 'fax')}
      />
    </Stack>
  );
};

export const FaxNumberValidation = ({
  faxNumber,
  isFaxNumberCorrect,
}: {
  faxNumber?: string;
  isFaxNumberCorrect?: GenericCorrectOptions;
}) => {
  const yesOption = useStringResource('suggestEditDialog', 'yesOption');
  const noOption = useStringResource('suggestEditDialog', 'noOption');
  return (
    <Stack spacing="xs">
      <Stack spacing="none">
        <Typography variant="button">
          <SR package="suggestEditDialog" id="faxNumberCorrectCheck" />
        </Typography>
        {!!faxNumber && <Typography variant="body1">{formatPhoneNumber(faxNumber)}</Typography>}
        <RadioGroupInput
          name="isFaxNumberCorrect"
          label=""
          options={[
            { label: yesOption, value: 'YES' },
            { label: noOption, value: 'NO' },
          ]}
        />
      </Stack>
      {isFaxNumberCorrect === 'NO' && <FaxInput />}
    </Stack>
  );
};
