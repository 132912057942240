import React, { useCallback, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, TwoColumnLayout, Typography } from '@garner-health/components-common';
import { SR } from '@garner-health/lib-ui-content-management';
import { Facility, Professional, ProviderDetailParams } from '~/clients';
import { SuggestEditDialog } from './suggest-edit-dialog';

export type ProfessionalLocationProps = {
  professional: Professional;
  facility?: never;
  searchParams: ProviderDetailParams;
};

export type FacilityLocationProps = {
  professional?: never;
  facility: Facility;
  searchParams: ProviderDetailParams;
};

export type LocationHeaderProps = ProfessionalLocationProps | FacilityLocationProps;

export const LocationHeader = () => {
  const [suggestEditDialogIsOpen, setSuggestEditDialogIsOpen] = useState(false);
  const openDialog = useCallback(() => setSuggestEditDialogIsOpen(true), [setSuggestEditDialogIsOpen]);

  return (
    <Box>
      <TwoColumnLayout type="1:2">
        <TwoColumnLayout.Column1>
          <Box>
            <Typography variant="h3">
              <SR package="providerDetails" id="locationSectionTitle" />
            </Typography>
          </Box>
        </TwoColumnLayout.Column1>
        <TwoColumnLayout.Column2>
          <Box textAlign="right">
            <Button icon={<EditIcon />} onClick={openDialog}>
              <SR package="providerDetails" id="suggestAnEdit" />
            </Button>
          </Box>
        </TwoColumnLayout.Column2>
      </TwoColumnLayout>
      <SuggestEditDialog isOpen={suggestEditDialogIsOpen} onClose={() => setSuggestEditDialogIsOpen(false)} />
    </Box>
  );
};
