import React from 'react';
import {
  Stack,
  Table,
  TableContext,
  TableDefinition,
  TwoColumnLayout,
  Typography,
} from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { Hours } from '~/clients';
import { displayTime } from '~/util';

type FormattedHours = {
  day: string;
  hours: string[];
};

const tableDefinition: TableDefinition<FormattedHours> = {
  columns: [
    {
      key: 'day',
      label: 'day',
      render: item => <Typography variant="body1">{item.day}</Typography>,
    },
    {
      key: 'hours',
      label: 'hours',
      render: item => (
        <Stack spacing="none">
          {item.hours.map(h => (
            <Typography key={h} variant="body1" color="common.muted">
              {h}
            </Typography>
          ))}
        </Stack>
      ),
    },
  ],
  keyResolver: item => item.day,
};

type ProviderHoursProps = {
  hours: Hours[];
};

/**
 * Formats hours returned on the provider object into a human readable format
 * @example
 * [
 *  {day: 'Monday', hours: ['9:00AM-5:00PM']},
 *  {day: 'Tuesday', hours: ['9:00AM-1:00PM', '2:00PM-6:00PM']}
 *]
 */
function formatHours(inputHours: Hours[], days: string[]): FormattedHours[] {
  const formattedHours: FormattedHours[] = [];
  days.forEach((day, idx) => {
    const hours = inputHours
      .filter(record => record.day === idx + 1) //idx + 1, since day 1 === "Monday"
      .map(h => `${displayTime(h.open)}-${displayTime(h.close)}`);

    if (hours.length > 0) {
      formattedHours.push({ day, hours });
    }
  });
  return formattedHours;
}

export const ProviderHours = ({ hours }: ProviderHoursProps) => {
  const days = [
    useStringResource('providerDetails', 'dayOneText'),
    useStringResource('providerDetails', 'dayTwoText'),
    useStringResource('providerDetails', 'dayThreeText'),
    useStringResource('providerDetails', 'dayFourText'),
    useStringResource('providerDetails', 'dayFiveText'),
    useStringResource('providerDetails', 'daySixText'),
    useStringResource('providerDetails', 'daySevenText'),
  ];
  const formattedHours = formatHours(hours, days);

  const tableContext: TableContext<FormattedHours> = {
    definition: tableDefinition,
    items: formattedHours,
  };

  return (
    <TwoColumnLayout type="1:1">
      <TwoColumnLayout.Column1>
        <Typography variant="h5" component="h4" fontWeight="medium">
          <SR package="providerDetails" id="hoursLabel" />
        </Typography>
        <Table context={tableContext} variant="dense" hideHeadRow />
      </TwoColumnLayout.Column1>
    </TwoColumnLayout>
  );
};
