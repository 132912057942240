import React, { useCallback } from 'react';
// Not supported shows the same SVG as not found
import NotSupportedSvg from 'jsx:~/assets/images/empty-search-results.svg';
import { Typography } from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { Event } from '~/analytics';
import { SupportCta, SvgWithSubText } from '../common';

export const NotSupportedSearch = () => {
  const header = useStringResource('search', 'notSupportedHeaderText');
  const interpolateFn = useCallback(
    (supportEmail: string) => <SupportCta supportEmail={supportEmail} event={Event.CARE_GOAL_SUPPORT} />,
    [],
  );
  return (
    <SvgWithSubText
      svg={<NotSupportedSvg role="img" aria-label={header} />}
      header={header}
      subHeader={
        <Typography variant="body1" color="common.muted">
          <SR package="search" id="notSupportedHeaderSubtext" interpolate={interpolateFn} />
        </Typography>
      }
    />
  );
};
