import React from 'react';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import PrintIcon from '@mui/icons-material/Print';
import { Address, formatPhoneNumber, FormattedAddress, Stack } from '@garner-health/components-common';
import { SR } from '@garner-health/lib-ui-content-management';
import { IconLabel } from '../common';

export type LocationInfoProps = {
  address?: Address;
  /** 10 character string in format '1112223333' */
  phoneNumber?: string;
  /** 10 character string in format '1112223333' */
  faxNumber?: string;
  /** Whether this provider has limited availability to accept new patients. */
  limitedAvailability: boolean;
};

/**
 * Component to display Address, phone number, fax number and whether the provider is accepting new patients for a location
 */
export const LocationInfo = ({ address, phoneNumber, faxNumber, limitedAvailability }: LocationInfoProps) => {
  const phone = phoneNumber ? formatPhoneNumber(phoneNumber) : undefined;
  const fax = faxNumber ? formatPhoneNumber(faxNumber) : undefined;
  return (
    <Stack spacing="sm" paddingBottom="xs">
      {!!address && <IconLabel icon={<LocationOnOutlinedIcon />} body={<FormattedAddress address={address} />} />}
      {!!phone && <IconLabel icon={<PhoneIcon />} body={phone} />}
      {!!fax && <IconLabel icon={<PrintIcon />} body={fax} />}
      {!!limitedAvailability && (
        <IconLabel
          icon={<EventBusyIcon />}
          body={<SR package="providerDetails" id="locationSectionLimitedAvailabilityAriaLabelText" />}
        />
      )}
    </Stack>
  );
};
