import { IdentityServiceV1 } from '@garner-health/api-client/identity-v1';
import createLogger from '~/logging';
import { baseClientOptions } from './common';

const log = createLogger(__filename);

export type IdentityProvider = {
  id: string;
  clientId: number;
  name: string;
  userPoolId: string;
};

class IdentityClient {
  private client: IdentityServiceV1;
  constructor() {
    this.client = new IdentityServiceV1(baseClientOptions);
  }

  async getIdentityProviders({
    emailDomain,
    userPoolId,
  }: {
    emailDomain: string;
    userPoolId: string;
  }): Promise<IdentityProvider[]> {
    return this.client
      .get('/identity-providers', {
        query: { email_domain: emailDomain, user_pool_id: userPoolId },
      })
      .onStatus(200, r =>
        r.json.items.map(item => {
          const result: IdentityProvider = {
            id: item.id,
            clientId: item.client_id,
            name: item.name,
            userPoolId: item.user_pool_id,
          };

          return result;
        }),
      )
      .catch(err => {
        log.error({ err, userPoolId, strEmailDomain: emailDomain }, 'Error retrieving identity providers');
        throw Error('Error retrieving identity providers');
      });
  }
}

export const identityClient = new IdentityClient();
