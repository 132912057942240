import React from 'react';
import { RadioGroupInput, Stack, Typography } from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';

export const AcceptsNewPatientsValidation = ({ isProfessional }: { isProfessional: boolean }) => {
  const yesOption = useStringResource('suggestEditDialog', 'yesOption');
  const noOption = useStringResource('suggestEditDialog', 'noOption');
  const labelId = isProfessional ? 'acceptsNewPatientsCorrectCheck' : 'facilityAcceptsNewPatientsCorrectCheck';

  return (
    <Stack spacing="none">
      <Typography variant="button">
        <SR package="suggestEditDialog" id={labelId} />
      </Typography>
      <RadioGroupInput
        name="acceptsNewPatients"
        label=""
        options={[
          { label: yesOption, value: 'YES' },
          { label: noOption, value: 'NO' },
        ]}
      />
    </Stack>
  );
};
